@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Jersey+20&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


/* atk starts */
.body {
    width: 100vw;
    overflow-x: hidden;
    color: white;
  }
  
  .desc {
    font-family: "Jersey 20", sans-serif;
  }
  
  .heading-about {
    font-family: "Press Start 2P", system-ui;
  }
  
  .onlybtn {
    background: linear-gradient(#69bdec, #2340ca);
    border: 2px solid;
    border-color: #909194;
  }
  
  .footer {
    background: linear-gradient(
      to right,
      #126d80,
      transparent,
      transparent,
      #126d80
    );
  }
  
  @media (max-width: 640px) {
    .footer {
      background: linear-gradient(
        to bottom,
        #126d80,
        transparent,
        transparent,
        #126d80
      );
    }
  }
  
  /* atk ends */